import React, { useEffect, useState, useRef } from 'react';
// import BarYear from '../comps/BarYear/BarYear.tsx';
import * as _ from 'lodash'
import Trend from '../charts/Trend.tsx'
import CashFlows from '../charts/CashFlows.tsx';
import { BarYearData } from '../charts/BarYear/Interfaces.tsx';
import BarYearType from '../charts/BarYear/BarYearType.tsx'
// import { fetchData } from '../comps/util.tsx';
import {url} from '../Asset.js'

import { useOutletContext, useParams } from 'react-router-dom';
import Balance from '../charts/Balance.tsx';


interface CompanyProps{
    code: string,
    token: string,
    clearToken: ()=>void
}

// interface BarYearType{
//     type: 'barYear',
//     year: string
// }

type GraphDatum = 'trend'|'bal'|'cashFlows'|'barYear'
type GraphData = GraphDatum[]

export default function Company(propsx:{}){
    const [token, clearToken]: [string,()=>void] = useOutletContext();
    const {code} = useParams()
    const props: CompanyProps = {
        code: code!,
        token: token,
        clearToken: clearToken
    }
    // console.log(token)
    const [barYearDataArr, setBarYearDataArr] = useState<BarYearData[]>([])
    const latestYearMap = useRef<Map<string,string>>(new Map())

    const graphData: GraphData = ['bal','cashFlows','trend','barYear',
        'barYear','barYear','barYear','barYear'
    ]

    // const graphData:GraphData = ['trend','cashFlows']

    const chooseGraph = (item: GraphDatum, data?:BarYearData)=>{
        if(item == "trend")
            return <Trend code={props.code}></Trend>
        else if(item == "bal")
            return <Balance code={props.code} token={props.token} 
        clearToken={props.clearToken}></Balance>
        else if(item == 'cashFlows')
            return <CashFlows code={props.code} token={props.token} 
                clearToken={props.clearToken}></CashFlows>
        else
            return <BarYearType data={data!} code={props.code}
                latestYearMap={latestYearMap} year={data!.year}/>
    }

    var barYearIdx = 0
    const graphJsx: React.JSX.Element[] = []
    for(var item of graphData){
        console.log(item,props.token)
        if(item=='barYear'){
            if(barYearDataArr.length > 0){
                graphJsx.push(<div className='Chart'>{chooseGraph(item,barYearDataArr[barYearIdx])}</div>)
                barYearIdx += 1
            }
        }else
            graphJsx.push(<div className='Chart'>{chooseGraph(item,barYearDataArr[barYearIdx])}</div>)
    }

    useEffect(() => {
        if(token)
        (async ()=>{
          const curl = `${url}/op_many?code=${props.code}`
          const response = await fetch(curl,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + props.token
            }
          })
          const result = await response.json()
          setBarYearDataArr(result as BarYearData[])
        })()
        return function(){setBarYearDataArr([])}
    }, [token])


    return <div>
        <div className="conainter">

        </div>
        {token && graphJsx}
    </div>
}