import { BarYearProps} from "../Interfaces.tsx";
import { BarYearData } from "./Interfaces.tsx";
import React, { useEffect } from 'react'
import {url} from '../../Asset.js'
// import Test from './Test.tsx'
import BarYearType from "./BarYearType.tsx";
import {fetchData} from '../util.tsx'

export default function BarYear(props:BarYearProps){
    const [data, setData] = 
    React.useState<BarYearData| undefined>(undefined)
    
    useEffect(() => {
        (async ()=>{
          const curl = `${url}/corp_year?code=${props.code}&year=${props.year}`
          const result = await fetchData(props,curl)
          setData(result as BarYearData)
        })()
        // async function fetchData() {
        //     console.log('fetch',props)
        //     // if(props.code === "SH601336" && (props.year === "latest" ||
        //     //   props.year ==="2022")){
        //     //     const res = {
        //     //       'year': '2022年报',
        //     //       "latest_year": "2022年报",
        //     //       "name": "新华保险",
        //     //       "region": "cn",
        //     //       "market": "A",
        //     //       "type": "insurance2023",
        //     //       'rev': 1080.48,
        //     //       'insure_rev': 568.78,
        //     //       'interest_rev': 320.01,
        //     //       'invest_income': 190.37,
        //     //       'rev_other': 1.32,
        //     //       'insure_cost': 759.92,
        //     //       'interest_cost': 11.73,
        //     //       'fu':1., 
        //     //       'admin':31.24, 
        //     //       'cost_other':55.72, 
        //     //       'op_inc':220.87,
        //     //       'equity':958.855,
        //     //       'roe':0.235532
        //     //     }
        //     //     setData(res as any)
        //     //     return
        //     //   }

        //     const curl = `${url}/corp_year?code=${props.code}&year=${props.year}`
        //     try{
        //         const response = await fetch(curl,{
        //           method: 'GET',
        //           headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': 'Bearer ' + props.token
        //           }
        //         })
        //         const result = await response.json()
        //         if(response.status === 200){
        //            setData(result as BarYearData)
        //         }else{
        //           console.log(result['detail'])
        //           props.clearToken()
        //           props.rmSelf()
        //           return undefined
        //         }
        //       }catch(e){
        //         console.error(e)
        //         return undefined
        //       }
        // }
        // fetchData()
        return function(){setData(undefined)}
    }, [])
    
    return <div>
      {data && <BarYearType data={data} 
        latestYearMap={props.lastestYearMap}
        code={props.code} 
        year={props.year} 
         />}
       {/* {data && (data.type === "default" ||
        data.type === undefined)
        && <BarYearDefault data={data} 
        latestYearMap={props.lastestYearMap}
        code={props.code} 
        year={props.year} 
         />
      }
      {data && data.type === "bank" && 
        <BarYearBank data={data} 
        latestYearMap={props.lastestYearMap}
        code={props.code} 
        year={props.year} 
         />
      } */}
    </div>
}