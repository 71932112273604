import React, { useEffect, useState, useRef } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {url} from '../Asset'
import { Outlet } from 'react-router-dom';

import SearchBar from '../comps/SearchBar.tsx';
import { Corp } from '../charts/Interfaces.tsx';

export default function Root(){
    const [open, setOpen] = React.useState(false);
    const [token, setToken] = useState('')
    const usernameRef = useRef<string>('');
    const [suggestions, setSuggestions] = useState<Corp[]>([])

    const onClick = (corp:Corp) => {
        //TODO how to set input value to '' dynamically?
        setSuggestions([])
        // setActiveCorp(corp)
        // console.log('in click',corps)
        // modifyGraphs(corp,year)
    }

    const items = suggestions.map((s)=>
        <li key={s.code}
            onClick={() => onClick(s)}>
          <a className="dropdown-item" href={`/company/${s.code}`}>
            {s.name}   {s.market}
          </a>
        </li>
      )

    const openLogin = () => 
        setOpen(true)

    const closeLogin = () => 
        setOpen(false)

    const openUserPanel = ()=>{}

    const clearToken = () => {
        setToken('')
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        usernameRef.current = ''
    }

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
        const username = localStorage.getItem("username");
        if (username) {
            usernameRef.current = username
            setToken(token)
        }
        }
    }, [])
    
    const matchFunc = (match:Corp[]) => {
        if(match.length > 0)
            setSuggestions(match)
        else
            setSuggestions([])
    }
    const focusFunc = (sugg:Corp[]) => {
        setSuggestions(sugg)
    }


    return <>
        <div className='content'>
    <div className='header ps-3 pb-3 pt-3 mb-2 border-bottom'>
      <span>Opture</span>
      <span className='ms-2'>运营结构分析</span>
      <div className='dropdown header-search'>
        <div> <SearchBar matchFunc={matchFunc}
            focusFunc={focusFunc}></SearchBar></div>
        { items.length > 0 && <ul className="dropdown-menu suggest">
            {items}
           {/* <li> <a className="dropdown-item" href="/SH600519">Action</a></li>
           <li> <a className="dropdown-item" href="#">Another action</a></li> */}
            {/* <a className="dropdown-item" href="#">Something else here</a> */}
        </ul>}
      </div>
      <span className='login me-5'>
         {!token &&  <a onClick={openLogin}>Login</a>}
         {token && <a onClick={openUserPanel}>{usernameRef.current}</a>}
      </span>
    </div>
    <Outlet context={[token,clearToken]}/>
    {/* <Search token={token} clearToken={clearToken}/> */}
    {/* { token &&
    <Company token={token} code={'SH600519'} clearToken={clearToken}/>
    } */}
    {/* <BarYear code='SZ002568' /> */}
    <Dialog
        open={open}
        onClose={closeLogin}
        PaperProps={{
          component: 'form',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            try {
              const tokenUrl = url + '/token'
              const response = await fetch(tokenUrl, {
                method: "POST",
                body: formData,
              });
              console.log(response.status)
              const result = await response.json();
              if (response.status === 200) {
                const formJson = Object.fromEntries((formData as any).entries());
                usernameRef.current = formJson.username
                setToken(result.access_token) 
                localStorage.setItem("username", formJson.username);
                localStorage.setItem("token", result.access_token);
                // localStorage.setItem("refresh_token", result.refresh_token);
                console.log(result)
                setOpen(false)
                // setName("");
                // setEmail("");
                // setMessage("User created successfully");
              } else {
                console.log(result)

                // setMessage("Some error occured");
              }
              // console.log("Success:", result);
            } catch (error) {
              console.error("Error:", error);
            }
            // const email = formJson.email;
            // console.log(formData,formJson);
            // closeLogin();
          },
        }}
      >
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            required
            margin="dense"
            id="username"
            name="username"
            label="User Name"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLogin}>Cancel</Button>
          <Button type="submit">Login</Button>
        </DialogActions>
      </Dialog>
    </div>
    </>
}