import React from 'react';
import * as d3 from "d3";
import { useEffect, useState, useRef } from 'react';
import {BaseChartProps} from './Interfaces.tsx'
import {url} from '../Asset.js'
import { GlobalParams, Scales, Axis, getMinLen } from './Elems.tsx'

interface TrendData{
    // in the order of increasing years
    name: string
    yearLabels: string []
    years: number[]
    revs: number[]
    op_incs: number[]
    roces: number[]
}


interface CirclesProps{
    xScale: Scales
    yScale: Scales
    params:GlobalParams
    xData: string[]
    yData: number[]
    color: string
}

function Circles(props:CirclesProps){
    const params = props.params
    const gx = params.margin.left * 
        params.width
    const gy = params.margin.top *
        params.height
    
    const minLen = getMinLen(params)
    const circles = props.xData.map((x,i)=>{
        const y = props.yData[i]
        return <circle key={i} cx={props.xScale(x as any)!}
            cy={props.yScale(y as any)}
            r={minLen!/60}
            fill={props.color}></circle>
})

    return <g transform={`translate(${gx},${gy})`}>
        {circles}
    </g>
}

export default function Trend(props:BaseChartProps){
    const [data, setData] = useState<TrendData>()
    // console.log('trend',data)
    const params: GlobalParams = {
        width: 460,
        height: 340,
        // margins are defined proportional to width and height
        margin:{
            top: 0.1,
            bottom: 0.07,
            left: 0.13,
            right: 0.1
        }
    }
    const [leftScale, setLeftScale] = useState<Scales>()
    const [rightScale, setRightScale] = useState<Scales>()
    const [bottomScale, setBottomScale] = useState<Scales>()

    // console.log(leftScale,bottomScale)

    const getYears = ()=>{
       return data!.years.map(x=>x.toString())
    }
    // const years = 

    useEffect(()=>{
        // const code = 'SH600519'
        // const year = 'latest'
        const code = props.code
        const curl = `${url}/trend?code=${code}`

        fetch(curl).then(res=>{
            res.json().then(resData=>setData(resData))
            // setData(res.json() as BarYearData)
        })
        return function(){setData(undefined)}
    },[])

    const getMin = (data:number[])=>{
        const min = d3.min(data)
        return min! > 0 ? min!*0.9 : min!*1.1
    }

    // console.log('trend',params)
    return <div>
        {data && 
        <svg width={params.width} height={params.height}>
            <text textAnchor='middle' x={params.width*.5} y={params.height*.05}>{data.name}</text>
            <Axis params={params}
                orient='left'
                start={getMin(data.op_incs)}
                end={d3.max(data.revs)}
                grid
                setScale={scale=>{
                    //cannot use setLeftScale(scale) here. See:
                    //https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
                    setLeftScale(()=>scale)}}></Axis>

            <Axis params={params}
                orient='right'
                start={getMin(data.roces)}
                end={d3.max(data.roces)}
                setScale={scale=>{setRightScale(()=>scale)}}></Axis>

            <Axis params={params}
                orient='bottom'
                labels={getYears()}
                setScale={scale=>{setBottomScale(()=>scale)}}></Axis>

            {leftScale && bottomScale && 
            <Circles params={params}
                xScale={bottomScale}
                yScale={leftScale}
                xData={getYears()}
                yData={data['revs']}
                color={'red'}></Circles>}

            {leftScale && bottomScale && 
            <Circles params={params}
                xScale={bottomScale}
                yScale={leftScale}
                xData={getYears()}
                yData={data['op_incs']}
                color={'pink'}></Circles>}
            
            {rightScale && bottomScale && 
            <Circles params={params}
                xScale={bottomScale}
                yScale={rightScale}
                xData={getYears()}
                yData={data['roces']}
                color={'blue'}></Circles>}
        </svg>}
    </div>
}