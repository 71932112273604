// 期初cash（固定柱），营收cash（浮动），投资cash（浮动），现金流cash（浮动），融资cash（浮动），现金及现金等价物（浮动）, 期末cash（固定柱）;...重复
// 下载过去10年公司现金流量表

import React from 'react';
import * as d3 from "d3";
import { useEffect, useState, useRef } from 'react';
import {TokenChartProps} from './Interfaces.tsx'
import {url} from '../Asset.js'
import { nanoid } from 'nanoid';
import { GlobalParams, Scales, Axis } from './Elems.tsx'
import * as _ from 'lodash'
import { fetchData } from './util.tsx';

interface CashFlowData{
    'begin_cce':number,
    'op_cash_net':number,
    'invest_cash_net':number,
    'fin_cash_net':number,
    'end_cce':number,
    'name':string,
    'date_type':string,
    'year':number
}

type CashFlowsData = CashFlowData[]

interface VizData{
    min: number;
    max: number;
    bars: (string | number)[][];
    tickMap: {
        [key: string]: string;
    };
}

interface BarsProps{
    xScale:Scales,
    yScale:Scales,
    params:GlobalParams,
    data: VizData,
    colors: string[]
}

function Bars(props:BarsProps){
    const params = props.params
    const gx = params.margin.left * 
        params.width
    const gy = params.margin.top *
        params.height
    
    const rg = props.yScale.range()
    const ys = d3.scaleLinear()
       .domain(props.yScale.domain() as any)
       .range([rg[1],rg[0]])
    
    const domain_size = props.data.max - props.data.min
    const sizeScale = d3.scaleLinear()
       .domain([0,domain_size])
       .range([rg[1],rg[0]])

    const bars = props.data.bars.map((d,i)=>{
        const width = 15
        const x = props.xScale(d[2] as any)
        // const width = (props.xScale as d3.ScalePoint<string>).bandwidth()
        let ref:number, bot:number, cidx:number
        if(d[0] < d[1]){
            ref = d[1] as number
            bot = d[0] as number
            cidx = 0
        }else{
            ref = d[0] as number
            bot = d[1] as number
            cidx = 1
        }
        if(!(d[2] as string).includes('_'))
            cidx = 2
        // const y = ys((props.data.max-props.data.min-(ref-props.data.min)) as any)
        const y = ys((props.data.max-ref+props.data.min) as any)
        const height = sizeScale((ref-bot) as any)
        // console.log(d[2],ref-bot,height)
        const color = props.colors[cidx]
        return <rect key={i}
            x={x}
            y={y}
            width={width}
            height={height}
            fill={color}></rect>
    })

    return <g transform={`translate(${gx},${gy})`}>
        {bars}
    </g>
}

function getVizData(data:CashFlowsData|undefined){
    if(_.isUndefined(data)){
        return data
    }else{

        let item = [0,data[0].begin_cce,
            `${data[0].year}`,
            `${data[0].year}`]
        const bars = [item]
        const label_mp = {
            'op_cash_net':'营',
            'invest_cash_net':'投',
            'fin_cash_net':'筹'}
       
        data.forEach(d=>{
            const year = d.year+1
            _.keys(label_mp).forEach(k=>{
                item = [
                    item[1],item[1]+d[k],
                    `${year}_${k}`,
                     label_mp[k]
                ]
                bars.push(item)
            })
            item = [0,d.end_cce,
            year.toString(), year.toString()]
            bars.push(item)
        })

        const min1 = _.min(bars.map(d=>d[0])) as number
        const min2 = _.min(bars.map(d=>d[1])) as number
        const min_val = min1! < min2! ? min1! : min2!

        const max1 = _.max(bars.map(d=>d[0])) as number
        const max2 = _.max(bars.map(d=>d[1])) as number
        const max_val = max1! > max2! ? max1! : max2!

        const tickMap = {} as {[key:string]:string}
        bars.forEach(d=>{
            tickMap[d[2] as string] = d[3] as string
        })

        return {'min':min_val,'max':max_val,
            bars:bars, tickMap:tickMap}
    }
}

export default function CashFlows(props:TokenChartProps){
    const [data, setData] = useState<CashFlowsData>()
    // console.log(data)
    const vizData = getVizData(data)
    // console.log(vizData)
    const params: GlobalParams = {
        width: 600,
        height: 340,
        // margins are defined proportional to width and height
        margin:{
            top: 0.1,
            bottom: 0.07,
            left: .07,
            right: 0.1
        }
    }
    const [leftScale, setLeftScale] = useState<Scales>()
    const [bottomScale, setBottomScale] = useState<Scales>()

    const xScaleFun = (d:(string|number)[],r:number[])=>
        d3.scaleBand()
           .domain(d as string[])
           .rangeRound(r)
           .padding(0.5)
    
    function containsOnlyDigits(str) {
            return /^\d+$/.test(str);
        }
    const textStyleFun = (texts:d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>) => {
        texts.filter(function(d,i){
            const node = d3.select(this)
            return containsOnlyDigits(node.text())
        })
            .style('font-size', '1.1em')
            .style('font-weight','bold')
    }
    // console.log(leftScale,bottomScale)

    // const getYears = ()=>{
    //    return data!.years.map(x=>x.toString())
    // }
    // const years = 

    useEffect(()=>{
        // const code = 'SH600519'
        // const year = 'latest'
        const code = props.code
        const curl = `${url}/cash_flows?code=${code}`

        // console.log(props)
        fetchData(props,curl).then(result=>setData(result))
        // fetch(curl).then(res=>{
        //     res.json().then(resData=>setData(resData))
        //     // setData(res.json() as BarYearData)
        // })
        return function(){setData(undefined)}
    },[])

    const getMin = (data:number[])=>{
        const min = d3.min(data)
        return min! > 0 ? min!*0.9 : min!*1.1
    }

    return <div>
        {vizData && 
        <svg width={params.width} height={params.height}>
            <text textAnchor='middle' x={params.width*.5} y={params.height*.05}>{data![0].name}</text>
            <Axis params={params}
                orient='left'
                grid
                start={vizData.min}
                end={vizData.max}
                setScale={scale=>{
                    //cannot use setLeftScale(scale) here. See:
                    //https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
                    setLeftScale(()=>scale)}}></Axis>

        
            <Axis params={params}
                orient='bottom'
                labels={vizData.bars.map(d=>d[2]) as string[]}
                tickFormat={(x:string|number) => vizData.tickMap[x]}
                scaleFun={xScaleFun}
                textStyleFun={textStyleFun}
                setScale={scale=>{setBottomScale(()=>scale)}}></Axis>
            
            {leftScale && bottomScale && 
                <Bars params={params} 
                    xScale={bottomScale}
                    yScale={leftScale}
                    data={vizData}
                    colors={['tomato','seagreen','orange']} />
            }
          
        </svg>}
    </div>
}